(function ($, site) {
  Drupal.behaviors.countryChooser = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      site.util = site.util || {};
      site.util.refreshSelectBoxes = site.util.refreshSelectBoxes || function () {};

      var $menu = $('.country-select__menu .menu--lvl-1', context);
      var $links = $('.menu__link', $menu);
      var $select = $('select.country-select__selectbox', context);
      var $options = [];
      var defaultCountry = typeof site.defaultCountry !== 'undefined' ? site.defaultCountry.toLowerCase() : '';
      // dynamically load flag image to country chooser from CMS.
      var flagUrl = $select.data('flag');

      $links.each(function (e) {
        var $me = $(this),
          href = $me.attr('href'),
          text = $me.text();
        var attr = '';

        if (text.toLowerCase() === defaultCountry) {
          attr += ' selected="selected"';
        }

        $options.push('<option value="' + href + '"' + attr + '>' + text + '</option>');
      });
      $select.append($options);
      $('.js-country-select.selectBox').attr('aria-label', $select.attr('aria-label'));

      $select.next().attr({
        'role': 'combobox',
        'aria-haspopup': 'true',
        'aria-expanded': 'false'
      });
      // use wrapper from scripts.js instead of "$select.selectBox('refresh');" for touch devices.
      site.util.refreshSelectBoxes($select);
      if (typeof flagUrl !== 'undefined') {
        $('.country-select__selectbox', context).css('background-image', 'url("' + flagUrl + '")').addClass('selectboxflag');
      }

      $select.on('change', function () {
        var loc = $(this).val();

        if (loc) {
          window.location = loc;

          return true;
        }
      });
    },
    attached: false
  };
})(jQuery, window.site || {});
